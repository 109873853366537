import React from "react";
import PropTypes from "prop-types";
import handleSVG from "../../helpers/handleSVG";

const AboutCoreValues = ({ title, values }) => {
	if (!title && !values.length) {
		return null;
	}

	return (
		<section className="about-core-values">
			<div className="container container--medium">
				{title &&
					<h2 className="section-title about-core-values__title">
						{title}
					</h2>
				}

				{values.length &&
					<div className="about-core-values__row">
						{values.map(({ icon, title, text }, index) => {
							return (
								<div className="about-core-values__item" key={index}>
									{icon &&
										<div className="about-core-values__item-icon" dangerouslySetInnerHTML={{ __html: handleSVG(icon) }}/>
									}

									<h3 className="about-core-values__item-title">
										{title}
									</h3>

									{text &&
										<div className="about-core-values__item-text">
											<p>
												{text}
											</p>
										</div>
									}
								</div>
							);
						})}
					</div>
				}

				<div className="about-core-values__hr"/>
			</div>
		</section>
	);
};

AboutCoreValues.propTypes = {
	title: PropTypes.string,
	values: PropTypes.array
};

export default AboutCoreValues;
