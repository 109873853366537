import React from "react";
import PropTypes from "prop-types";

const AboutStatistics = ({ subtitle, title, text, statistics }) => {
	if (!subtitle && !title && !text && !statistics) {
		return null;
	}

	return (
		<div className="container container--medium">
			<div className="about-who-we-are__row">
				{(subtitle || title || text) &&
					<div className="about-who-we-are__info">
						{subtitle &&
							<p className="about-who-we-are__label">
								{subtitle}
							</p>
						}

						{title &&
							<h2 className="about-who-we-are__title">
								{title}
							</h2>
						}

						{text &&
							<div className="about-who-we-are__text" dangerouslySetInnerHTML={{ __html: text }}/>
						}
					</div>
				}

				{statistics.length &&
					<div className="about-who-we-are__features">
						<ul className="about-who-we-are__list">
							{statistics.map(({ title, text }, index) => {
								return (
									<li key={index}>
										<strong>
											{title}
										</strong>

										{text}
									</li>
								);
							})}
						</ul>
					</div>
				}
			</div>
		</div>
	);
};

AboutStatistics.propTypes = {
	subtitle: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	statistics: PropTypes.array
};

export default AboutStatistics;
