import React from "react";
import PropTypes from "prop-types";
import CompanyLogos from "../../components/CompanyLogos";
import createValidLink from "../../helpers/urlFixer";
import {Link} from "gatsby";

const AboutOurExpertise = ({ subtitle, title, expertise, button }) => {
	if (!subtitle && !title && !expertise.length) {
		return null;
	}
	return (
		<section className="about-our-expertise">
			<div className="container container--medium">
				{subtitle &&
					<p className="about-our-expertise__label">
						{subtitle}
					</p>
				}

				{title &&
					<h2 className="about-our-expertise__title section-title">
						{title}
					</h2>
				}

				{expertise.length &&
					<div className="about-our-expertise__logos">
						<CompanyLogos
							logos={expertise}
						/>
					</div>
				}

				{button.buttonText && button.buttonLink &&
					<Link
						to={createValidLink(button.buttonLink.url)}
						className="button button--transparent button--large about-our-expertise__button"
					>
						{button.buttonText}
					</Link>
				}

				<div className="about-our-expertise__hr"/>
			</div>
		</section>
	);
};

AboutOurExpertise.propTypes = {
	subtitle: PropTypes.string,
	title: PropTypes.string,
	expertise: PropTypes.array,
	button: PropTypes.object
};

export default AboutOurExpertise;