import React from "react";
import PropTypes from "prop-types";

import IconUTDGradient from "../../icons/IconUTDGradient";

const AboutOurMission = ({ subtitle, title, text }) => {
	if (!subtitle && !title && !text) {
		return null;
	}

	return (
		<section className="about-our-mission">
			<div className="about-our-mission__container">
				<div className="about-our-mission__logo">
					<IconUTDGradient/>
				</div>

				{subtitle &&
					<p className="about-our-mission__label">
						{subtitle}
					</p>
				}

				{title &&
					<h3 className="about-our-mission__title">
						{title}
					</h3>
				}

				{text &&
					<div className="about-our-mission__text" dangerouslySetInnerHTML={{ __html: text }}/>
				}
			</div>
		</section>
	);
};

AboutOurMission.propTypes = {
	subtitle: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string
};

export default AboutOurMission;