import React from "react";
import PropTypes from "prop-types";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";

const AboutGrowWithUs = ({ title, text, image }) => {
	const gatsbyImageData = image?.localFile?.childImageSharp?.gatsbyImageData;

	if (!title && !text && !gatsbyImageData) {
		return null;
	}

	const backgroundImage = convertToBgImage(gatsbyImageData);

	return (
		<div className="about-who-we-are__media-content-wrapper">
			<div className="container container--medium">
				<div className="about-who-we-are__media-content">
					{backgroundImage &&
						<div className="about-who-we-are__media-content-image-wrapper">
							<BackgroundImage
								Tag="div"
								className="about-who-we-are__media-content-image"
								{...backgroundImage}
							/>
						</div>
					}

					{(title || text) &&
						<div className="about-who-we-are__media-content-info">
							{title &&
								<h2 className="about-who-we-are__title">
									{title}
								</h2>
							}

							{text &&
								<div className="about-who-we-are__text" dangerouslySetInnerHTML={{ __html: text }}/>
							}
						</div>
					}
				</div>
			</div>
		</div>
	);
};

AboutGrowWithUs.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string
};

export default AboutGrowWithUs;
