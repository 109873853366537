import React from "react";
import PropTypes from "prop-types";

const AboutTextSection = ({ title, text }) => {
	if (!title && !text) {
		return null;
	}

	return (
		<section className="about-text-section">
			<div className="container container--medium">
				{title &&
					<h3 className="about-text-section__title">
						{title}
					</h3>
				}

				{text &&
					<div className="about-text-section__text" dangerouslySetInnerHTML={{ __html: text}}/>
				}
			</div>
		</section>
	);
};

AboutTextSection.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string
};

export default AboutTextSection;