import React from "react";
import PropTypes from "prop-types";
import ProjectItem from "./ProjectItem";
import createValidLink from "../helpers/urlFixer";
import {Link} from "gatsby";

const Portfolio = ({ title, projects, button }) => {
	if (!projects || !projects?.length) {
		return null;
	}

	return (
		<section className="our-portfolio-general">
			{title &&
				<div className="container container--medium container--mobile-small">
					<h2 className="our-portfolio-general__title section-title">
						{title}
					</h2>
				</div>
			}

			<div className="container container--mobile-small">
				<div className="our-portfolio-general__blocks">
					<div className="our-portfolio-general__blocks-wrapper">
						{projects.map((project, index) => {
							return (
								<ProjectItem
									key={index}
									title={project.title}
									slug={project.slug}
									technologies={project.technologies}
									squaredImage={project.acfProject?.squaredImage}
								/>
							);
						})}
					</div>

					{button.buttonText && button.buttonLink &&
						<div className="our-portfolio-general__link-wrapper">
							<Link
								to={createValidLink(button.buttonLink.url)}
								className="button button--transparent button--large"
							>
								{button.buttonText}
							</Link>
						</div>
					}
				</div>
			</div>
		</section>
	);
};

Portfolio.propTypes = {
	title: PropTypes.string,
	projects: PropTypes.array.isRequired
};

export default Portfolio;
