import React from "react";

const IconUTDGradient = () => (
	<svg width="593" height="205" viewBox="0 0 593 205" fill="none" aria-hidden="true">
		<path d="M28.8933 0V123.124C28.8933 137.587 31.7541 149.611 37.4755 159.193C45.9623 173.657 60.266 180.889 80.3864 180.889C104.512 180.889 120.913 173.069 129.591 157.43C134.263 148.933 136.6 137.497 136.6 123.124V0H165.493V111.869C165.493 136.367 162.012 155.215 155.051 168.414C142.273 192.46 118.148 204.483 82.675 204.483C47.202 204.483 23.1242 192.46 10.4417 168.414C3.48055 155.215 0 136.367 0 111.869V0H28.8933Z" fill="url(#a)"/>
		<path d="M377.244 0V23.7298H306.441V199.194H277.548V23.7298H206.745V0H377.244Z" fill="url(#b)"/>
		<path d="M498.739 176.143C508.37 176.143 516.285 175.193 522.483 173.295C533.545 169.77 542.604 162.99 549.66 152.955C555.286 144.91 559.339 134.604 561.818 122.039C563.248 114.536 563.964 107.575 563.964 101.157C563.964 76.4776 558.767 57.313 548.373 43.6628C538.074 30.0125 521.434 23.1874 498.453 23.1874H447.961V176.143H498.739ZM419.354 0H504.46C533.354 0 555.763 9.7179 571.688 29.1537C585.896 46.6911 593 69.1553 593 96.5462C593 117.7 588.804 136.819 580.413 153.904C565.632 184.098 540.22 199.194 504.174 199.194H419.354V0Z" fill="url(#c)"/>
		<defs>
			<linearGradient id="a" x1="296.5" y1="0" x2="296.5" y2="204.483" gradientUnits="userSpaceOnUse">
				<stop stopColor="#fff"/>
				<stop offset="1" stopColor="#fff" stopOpacity="0.2"/>
			</linearGradient>
			<linearGradient id="b" x1="296.5" y1="0" x2="296.5" y2="204.483" gradientUnits="userSpaceOnUse">
				<stop stopColor="#fff"/>
				<stop offset="1" stopColor="#fff" stopOpacity="0.2"/>
			</linearGradient>
			<linearGradient id="c" x1="296.5" y1="0" x2="296.5" y2="204.483" gradientUnits="userSpaceOnUse">
				<stop stopColor="#fff"/>
				<stop offset="1" stopColor="#fff" stopOpacity="0.2"/>
			</linearGradient>
		</defs>
	</svg>
);

export default IconUTDGradient;






