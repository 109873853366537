import React from "react";

import AboutStatistics from "../../components/AboutUs/AboutStatistics";
import AboutGrowWithUs from "../../components/AboutUs/AboutGrowWithUs";

const AboutWhoWeAre = ({ whoWeAre, growWithUs }) => {
	return (
		<section className="about-who-we-are">
			<AboutStatistics
				{...whoWeAre}
			/>

			<AboutGrowWithUs
				{...growWithUs}
			/>
		</section>
	);
};

export default AboutWhoWeAre;