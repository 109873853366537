import React from "react";
import PropTypes from "prop-types";
import VideoSimpleBanner from "../../components/VideoSimpleBanner";
import PageHeader from "../../components/PageHeader";

const AboutBanner = ({ title, video, poster, pageHeader }) => {
	const { mediaItemUrl, mimeType } = video ?? {};
	const headerBelowBanner = pageHeader?.position === 'below' || pageHeader?.position === 'bellow';

	const titleBelowBannerMarkup = headerBelowBanner && pageHeader?.title
		? <PageHeader {...pageHeader} isGeneral={true} />
		: null;

	return (
		<>
			{mediaItemUrl
				? (
					<section className="about-banner">
						<VideoSimpleBanner
							poster={poster}
							title={title}
							html5VideoUrl={mediaItemUrl}
							htmlVideoType={mimeType}
							pageHeader={pageHeader}
						/>
					</section>
				)
				: titleBelowBannerMarkup
			}
		</>
	);
};

AboutBanner.propTypes = {
	title: PropTypes.string
};

export default AboutBanner;
