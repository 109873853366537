import React from "react";
import PropTypes from "prop-types";
import Portfolio from "../../components/Portfolio";

const AboutPortfolio = ({ title, projects, button }) => {
	return (
		<Portfolio
			title={title}
			projects={projects}
			button={button}
		/>
	);
};

AboutPortfolio.propTypes = {
	title: PropTypes.string,
	projects: PropTypes.array.isRequired
};

export default AboutPortfolio;