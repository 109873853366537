import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import DefaultHelmet from "../seo/DefaultHelmet";

import AboutBanner from "../sections/AboutUs/AboutBanner";
import AboutWhoWeAre from "../sections/AboutUs/AboutWhoWeAre";
import AboutOurMission from "../sections/AboutUs/AboutOurMission";
import AboutCoreValues from "../sections/AboutUs/AboutCoreValues";
import AboutOurExpertise from "../sections/AboutUs/AboutOurExpertise";
import AboutTextSection from "../sections/AboutUs/AboutTextSection";
import AboutPortfolio from "../sections/AboutUs/AboutPortfolio";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";

const AboutUs = ({ pageContext }) => {
	const {
		banner,
		whoWeAre,
		growWithUs,
		ourMission,
		ourCoreValues,
		ourExpertise,
		expertiseItems,
		seoText,
		ourPortfolio,
		metaData,
		pageHeader,
	} = pageContext;

	const pageTitle = metaData?.title || "About us";
	const pageDescription = metaData?.description || "Page about UTD";
	const pageKeywords = "web development, UTD, eCommerce, design, development, marketing, growth optimization, customer experience, Shopify, Shopify Shops, Shopify Stores, Shopify APPs, Shopify Themes, brand development, business growth, premium services, innovative technologies"

    useBeaconWidget(beaconWidgetIds.GENERAL);

	return (
		<DefaultLayout>
			<DefaultHelmet title={pageTitle} description={pageDescription} keywords={pageKeywords}/>

			<AboutBanner
				{...banner}
				pageHeader={pageHeader}
			/>

			<AboutWhoWeAre
				whoWeAre={whoWeAre}
				growWithUs={growWithUs}
			/>

			<AboutOurMission
				{...ourMission}
			/>

			<AboutCoreValues
				{...ourCoreValues}
			/>

			<AboutOurExpertise
				{...ourExpertise}
				{...expertiseItems}
			/>

			<AboutTextSection
				{...seoText}
			/>

			<AboutPortfolio
				{...ourPortfolio}
			/>
		</DefaultLayout>
	);
};

export default AboutUs;
